import { AnimatePresence, motion } from "framer-motion";
import "./task-inner.css";
import "./done-coins.css";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Task from "types/task";
import Icon from "UI/Icon";
import TaskModalInfo from "./TaskModalInfo";
import TaskModalReward from "../../../../../UI/RewardScreen";
import { TaskDoneResponce } from "api/done-task";
import AppContext from "utils/app-context";
type Props = {
  task: Task | null;
  openedTask: Dispatch<SetStateAction<Task | null>>;
  refreshTasks: () => Promise<void>
};

const TaskModal = ({ task, openedTask, refreshTasks }: Props) => {
  const [taskDoneResult, setTaskDoneResult] = useState<TaskDoneResponce | null>(null);
  const {setUser,user} = useContext(AppContext)
  const close = () => {
    setTaskDoneResult(null)
    openedTask(null)
  }
  const onEndAnimation = async () => {
    refreshTasks()
    if(user) setUser({...user,balance:taskDoneResult?.balance || user.balance})
  }
  
  return (
    <AnimatePresence>
      {task && (
        <motion.div
          initial={{ translateY: -100, opacity: 0 }}
          animate={{ translateY: 0, opacity: 1 }}
          exit={{ translateY: -100, opacity: 0 }}
          transition={{ ease: "easeInOut" }}
          key={task?.id}
          className="screen-task-inner"
        >
          <div className="close" onClick={close}>
            <Icon icon="close"/>
          </div>
          {
            taskDoneResult
              ? <TaskModalReward rewardCount={Number(task.reward)} rewardData={taskDoneResult} close={close} onEndAnimation={onEndAnimation}/>
              : <TaskModalInfo task={task} setReward={(r:TaskDoneResponce) => setTaskDoneResult(r)}/>
          }
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TaskModal;
