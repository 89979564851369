import Friends from "pages/Main/pages/Friends/Friends";
import Home from "pages/Main/pages/Home/Home";
import translations from "../i18n"
import Tasks from "pages/Main/pages/Tasks/Tasks";
import Upgrade from "pages/Main/pages/Upgrade/Upgrade";
import Wallet from "pages/Main/pages/Wallet/Wallet";
import { RouteObject } from "react-router-dom";
import Icon from "UI/Icon";
type RouteItems = {
  route: RouteObject;
  navBarIcon?: JSX.Element;
  title: keyof typeof translations['en']['pageTitles'];
  comingSoon?:true
};

const routes: RouteItems[] = [
  {
    route: {
      path: "home",
      element: <Home />,
    },
    title: "hero",
    navBarIcon: <Icon icon="home" />,
  },
  {
    route: {
      path: "upgrade",
      element: <Upgrade />,
    },
    title: "upgrade",
    navBarIcon: <Icon icon="upgrage" />,
  },
  {
    route: {
      path: "tasks",
      element: <Tasks />,
    },
    title: "tasks",
    navBarIcon: <Icon icon="tasks" />,
  },
  {
    route: {
      path: "friends",
      element: <Friends />,
    },
    title: "frens",
    navBarIcon: <Icon icon="friends" />,
  },
  {
    route: {
      path: "wallet",
      element: <Wallet />,
    },
    title: "wallet",
    navBarIcon: <Icon icon="wallet" />,
  },
];

export default routes;
