import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
    home:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 15.2549C3 12.3944 3 10.9641 3.649 9.77843C4.298 8.59276 5.48369 7.85689 7.85504 6.38516L10.355 4.83359C12.8617 3.27786 14.1151 2.5 15.5 2.5C16.8849 2.5 18.1382 3.27786 20.645 4.83359L23.145 6.38515C25.5164 7.85689 26.702 8.59276 27.351 9.77843C28 10.9641 28 12.3944 28 15.2549V17.1563C28 22.0323 28 24.4704 26.5355 25.9851C25.0711 27.5 22.714 27.5 18 27.5H13C8.28595 27.5 5.92894 27.5 4.46446 25.9851C3 24.4704 3 22.0323 3 17.1563V15.2549Z" stroke="white" strokeWidth="1.5"/>
            <path d="M19.25 22.5H11.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    ),
    upgrage:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5002 20C8.30026 20 7.02479 12.8244 6.79884 7.13309C6.73599 5.54998 6.70456 4.75841 7.29921 4.02604C7.89385 3.29367 8.60556 3.17359 10.029 2.93343C11.434 2.69636 13.2707 2.5 15.5002 2.5C17.7296 2.5 19.5664 2.69636 20.9714 2.93343C22.3949 3.17359 23.1065 3.29367 23.7011 4.02604C24.2959 4.75841 24.2644 5.54998 24.2015 7.13309C23.9756 12.8244 22.7001 20 15.5002 20Z" stroke="white" strokeWidth="1.5"/>
            <path d="M15.5 20V23.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M19.875 27.5H11.125L11.549 25.3799C11.6659 24.7956 12.1789 24.375 12.7748 24.375H18.2252C18.8211 24.375 19.3341 24.7956 19.451 25.3799L19.875 27.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.25 6.25L25.4358 6.64526C26.6734 7.05779 27.2921 7.26405 27.646 7.7551C28 8.24615 28 8.89841 27.9999 10.2029V10.2936C27.9999 11.3695 27.9999 11.9075 27.7409 12.3476C27.4819 12.7877 27.0116 13.049 26.0711 13.5715L22.375 15.625" stroke="white" strokeWidth="1.5"/>
            <path d="M6.74993 6.25L5.56415 6.64526C4.32658 7.05779 3.70779 7.26405 3.35388 7.7551C2.99995 8.24615 2.99996 8.89841 3 10.2029V10.2936C3.00004 11.3695 3.00005 11.9075 3.25904 12.3476C3.51801 12.7877 3.98829 13.049 4.92881 13.5715L8.62493 15.625" stroke="white" strokeWidth="1.5"/>
            <path d="M14.4324 7.52814C14.9074 6.67605 15.1449 6.25 15.5 6.25C15.8551 6.25 16.0926 6.67605 16.5676 7.52814L16.6905 7.74859C16.8254 7.99073 16.8929 8.1118 16.9981 8.19167C17.1034 8.27156 17.2344 8.30121 17.4965 8.36051L17.7351 8.41451C18.6575 8.62321 19.1187 8.72756 19.2285 9.0804C19.3382 9.43324 19.0237 9.8009 18.395 10.5362L18.2322 10.7265C18.0536 10.9354 17.9642 11.0399 17.924 11.1691C17.8839 11.2984 17.8974 11.4378 17.9244 11.7166L17.949 11.9704C18.044 12.9515 18.0916 13.442 17.8044 13.6601C17.517 13.8781 17.0852 13.6794 16.2216 13.2817L15.9982 13.1789C15.7527 13.0659 15.6301 13.0094 15.5 13.0094C15.3699 13.0094 15.2472 13.0659 15.0017 13.1789L14.7784 13.2817C13.9147 13.6794 13.483 13.8781 13.1956 13.6601C12.9084 13.442 12.9559 12.9515 13.051 11.9704L13.0756 11.7166C13.1026 11.4378 13.1161 11.2984 13.076 11.1691C13.0357 11.0399 12.9464 10.9354 12.7677 10.7265L12.605 10.5362C11.9762 9.8009 11.6618 9.43324 11.7715 9.0804C11.8813 8.72756 12.3424 8.62321 13.2649 8.41451L13.5035 8.36051C13.7656 8.30121 13.8966 8.27156 14.0019 8.19167C14.1071 8.1118 14.1746 7.99073 14.3095 7.74859L14.4324 7.52814Z" stroke="white" strokeWidth="1.5"/>
            <path d="M23 27.5H8" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    ),
    tasks:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0046 5H12.9954C8.27261 5 5.91124 5 4.44407 6.46446C3.34577 7.56072 3.06963 9.15715 3.0002 11.8681C2.9913 12.2156 3.27652 12.4933 3.61345 12.5815C4.68843 12.8626 5.48152 13.8389 5.48152 15C5.48152 16.1611 4.68843 17.1374 3.61345 17.4185C3.27652 17.5066 2.9913 17.7844 3.0002 18.132C3.06963 20.8429 3.34577 22.4392 4.44407 23.5355C5.91124 25 8.27261 25 12.9954 25H18.0046C22.7274 25 25.0888 25 26.556 23.5355C27.6543 22.4392 27.9304 20.8429 27.9997 18.132C28.0087 17.7844 27.7235 17.5066 27.3865 17.4185C26.3116 17.1374 25.5185 16.1611 25.5185 15C25.5185 13.8389 26.3116 12.8626 27.3865 12.5815C27.7235 12.4933 28.0087 12.2156 27.9997 11.8681C27.9304 9.15715 27.6543 7.56072 26.556 6.46446C25.0888 5 22.7274 5 18.0046 5Z" stroke="white" strokeWidth="1.5"/>
            <path d="M14.4324 12.5281C14.9074 11.6761 15.1449 11.25 15.5 11.25C15.8551 11.25 16.0926 11.6761 16.5676 12.5281L16.6905 12.7486C16.8254 12.9908 16.8929 13.1117 16.9981 13.1916C17.1034 13.2715 17.2344 13.3013 17.4965 13.3605L17.7351 13.4145C18.6575 13.6232 19.1187 13.7275 19.2285 14.0804C19.3382 14.4333 19.0237 14.8009 18.395 15.5362L18.2322 15.7265C18.0536 15.9354 17.9642 16.0399 17.924 16.1691C17.8839 16.2984 17.8974 16.4377 17.9244 16.7166L17.949 16.9704C18.044 17.9515 18.0916 18.442 17.8044 18.6601C17.517 18.8781 17.0852 18.6794 16.2216 18.2817L15.9982 18.1789C15.7527 18.0659 15.6301 18.0094 15.5 18.0094C15.3699 18.0094 15.2472 18.0659 15.0017 18.1789L14.7784 18.2817C13.9147 18.6794 13.483 18.8781 13.1956 18.6601C12.9084 18.442 12.9559 17.9515 13.051 16.9704L13.0756 16.7166C13.1026 16.4377 13.1161 16.2984 13.076 16.1691C13.0357 16.0399 12.9464 15.9354 12.7677 15.7265L12.605 15.5362C11.9762 14.8009 11.6618 14.4333 11.7715 14.0804C11.8813 13.7275 12.3424 13.6232 13.2649 13.4145L13.5035 13.3605C13.7656 13.3013 13.8966 13.2715 14.0019 13.1916C14.1071 13.1117 14.1746 12.9908 14.3095 12.7486L14.4324 12.5281Z" stroke="white" strokeWidth="1.5"/>
        </svg>
    ),
    friends:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 12.5C15.7614 12.5 18 10.2614 18 7.5C18 4.73858 15.7614 2.5 13 2.5C10.2386 2.5 8 4.73858 8 7.5C8 10.2614 10.2386 12.5 13 12.5Z" stroke="white" strokeWidth="1.5"/>
            <path d="M23 21.875C23 24.9816 23 27.5 13 27.5C3 27.5 3 24.9816 3 21.875C3 18.7684 7.47715 16.25 13 16.25C18.5229 16.25 23 18.7684 23 21.875Z" stroke="white" strokeWidth="1.5"/>
            <path id='friends-heart-icon' d="M20.5678 14.3715C20.9849 14.8414 21.4957 15.2351 21.9523 15.5868C22.0565 15.6671 22.1579 15.7452 22.2547 15.8216L22.2597 15.8255C22.4446 15.9713 22.6381 16.1222 22.8322 16.2357C23.028 16.3502 23.2415 16.4371 23.4677 16.4371C23.6939 16.4371 23.9073 16.3503 24.103 16.2358C24.2972 16.1223 24.4906 15.9713 24.6756 15.8255M20.5678 14.3715C20.0488 13.7868 19.6572 13.0671 19.6572 12.1243C19.6572 11.1679 20.1981 10.346 20.9675 9.99413C21.6025 9.70373 22.356 9.74451 23.0713 10.2265L23.4677 10.4936L23.8641 10.2265C24.5795 9.74451 25.3329 9.70372 25.968 9.99412C26.7373 10.3459 27.2782 11.1679 27.2782 12.1243C27.2782 13.0671 26.8866 13.7868 26.3676 14.3715C25.9505 14.8414 25.4397 15.2351 24.9831 15.5868C24.8789 15.6671 24.7775 15.7452 24.6807 15.8216L24.6756 15.8255M20.5678 14.3715L23.4677 16.7953L24.6756 15.8255" stroke="white"/>
        </svg>
    ),
    wallet:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.5416 11.25H23.2885C21.0581 11.25 19.25 12.9289 19.25 15C19.25 17.0711 21.0581 18.75 23.2885 18.75H26.5416C26.6459 18.75 26.6979 18.75 26.7419 18.7474C27.416 18.7063 27.9529 18.2078 27.9971 17.5818C28 17.5409 28 17.4925 28 17.3959V12.6041C28 12.5075 28 12.4591 27.9971 12.4182C27.9529 11.7923 27.416 11.2937 26.7419 11.2527C26.6979 11.25 26.6459 11.25 26.5416 11.25Z" stroke="white" strokeWidth="1.5"/>
            <path d="M26.7063 11.25C26.6091 8.90962 26.2957 7.47469 25.2855 6.46446C23.8211 5 21.464 5 16.75 5H13C8.28595 5 5.92894 5 4.46446 6.46446C3 7.92894 3 10.286 3 15C3 19.714 3 22.0711 4.46446 23.5355C5.92894 25 8.28595 25 13 25H16.75C21.464 25 23.8211 25 25.2855 23.5355C26.2957 22.5254 26.6091 21.0904 26.7063 18.75" stroke="white" strokeWidth="1.5"/>
            <path d="M22.989 15H23.0003" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    close:(
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18.75 11.25L11.25 18.75M11.25 11.25L18.75 18.75M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
              ></path>
            </svg>
    ),
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    )
}

export default Icon