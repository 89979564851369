import { CSSProperties } from "react";

const getStyle = (level: number,func?:() => void) => {
  if (level > 15) level = 15

  const characterColor =
    level > 10
      ? "#9C59CC"
      : level > 5
      ? "#0048D9"
      : level === 1
      ? "#ffffff5e"
      : "#FFFFFF";

  const fogStyles: CSSProperties[] = [
    {opacity:0},
    {opacity:0.4,transform:'translateY(30px)'},
    {opacity:0.7,transform:'translateY(30px)'},
    {opacity:1},
    {opacity:1,height:500},

    {opacity:0.4,transform:'translateY(30px)'},
    {opacity:0.7,transform:'translateY(30px)'},
    {opacity:1},
    {opacity:1,height:450},
    {opacity:1,height:500},

    {opacity:0.4,transform:'translateY(30px)'},
    {opacity:0.7,transform:'translateY(30px)'},
    {opacity:1},
    {opacity:1,height:450},
    {opacity:1,height:500},
  ];
  const characterStyles: CSSProperties[] = [
    { filter: `drop-shadow(0px 0px 15px ${characterColor})` },
    { filter: `drop-shadow(0px -2px 6px ${characterColor})` },
    { filter: `drop-shadow(0px -3px 15px ${characterColor})` },
    { filter: `drop-shadow(0px -17px 44px ${characterColor})` },
    { filter: `drop-shadow(0px -30px 96px ${characterColor})` },

    { filter: `drop-shadow(0px -2px 6px ${characterColor})` },
    { filter: `drop-shadow(0px -3px 15px ${characterColor})` },
    { filter: `drop-shadow(0px -4px 44px ${characterColor})` },
    { filter: `drop-shadow(0px -4px 44px ${characterColor})` },
    { filter: `drop-shadow(0px -4px 96px ${characterColor})` },

    { filter: `drop-shadow(0px -2px 6px ${characterColor})` },
    { filter: `drop-shadow(0px -3px 15px ${characterColor})` },
    { filter: `drop-shadow(0px -4px 44px ${characterColor})` },
    { filter: `drop-shadow(0px -4px 44px ${characterColor})` },
    { filter: `drop-shadow(0px -4px 96px ${characterColor})` },
  ];

  return {
    backgroundFog: (
      <img
        src={`/img/fog/${
          level > 10 ? 'purple' : level > 5 ? 'blue' : 'white'
        }.png`}
        className="tuman"
        style={fogStyles[level - 1]}
      />
    ),
    character: (
      <img
        onClick={func}
        src={`/img/character/${
          level === 1 ? "min" : level === 15 ? "max" : "normal"
        }.png`}
        className="farming-img"
        style={characterStyles[level - 1]}
      />
    ),
  };
};
export default getStyle;
