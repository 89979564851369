import doneTask, { TaskDoneResponce } from "api/done-task";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import Task from "types/task";
import Icon from "UI/Icon";
import formatNum from "utils/format-num";
import tg from "utils/tg";
import { MainContext } from "App";
import AppContext from "utils/app-context";

type Props = {
    task:Task,
    setReward:(r:TaskDoneResponce) => void
};

const TaskModalInfo = ({task,setReward}: Props) => {
  const {setNotification} = useContext(MainContext)
    const initialAvailableCheck = task.status === 2 || !task.url.length
    const [availableCheck, setAvailableCheck] = useState(initialAvailableCheck)
    const [loading, setloading] = useState(false)
    const {t} = useContext(AppContext)
    const errors = t('errors')
    const text = t('tasksPage')
    
    const openUrl = (urL:string) => {
        try {
          tg.openTelegramLink(urL);
        } catch (error) {
          tg.openLink(urL);
        }
        setTimeout(() => setAvailableCheck(true),1000)
      }
    
      const checkTask = async () => {
        if (!availableCheck || !task) return
        setloading(true)
        const result = await doneTask(task.id)
        
        if (result) {
          switch (result?.status) {
            case 1:
              // ведем на reward screen
              setReward(result)
            break;
          
             case 2:
              // alert что таска еще висит в ожидании
              setNotification({text:errors.pendingTask})
              break;
          
            default:
              break;
          }
        } else tg.showAlert(errors.claimTask)

        setloading(false)
      } 

  return (
    <>
      <div className="task-inner-img">
        <img src={task.icon_l} alt="" />
      </div>
      <h2 className="task-inner-title">{task.title}</h2>
      <p className="task-inner-text" dangerouslySetInnerHTML={{__html:task.description}}></p>
      <div className="task-inner-money">
        {task.reward_type === "balance" ? (
          <img src="/img/coin3.png" alt="" />
        ) : (
          <span className="big-green" style={{ fontSize: 35, marginRight: 20 }}>
            REP
          </span>
        )}
        <div className="task-inner-money-count">+ {formatNum(task.reward)}</div>
      </div>
      {task.status !== 1 && (
        <>
          {!!task.url.length && <div
            className="task-inner-button-go"
            onClick={() => openUrl(task.url)}
          >
            {text.go}
          </div>}
          <div
            className="task-inner-button-check"
            style={{ opacity: availableCheck ? 1 : 0.5 }}
            onClick={checkTask}
          >
            {!availableCheck && <CheckIcon />}
            {loading ? <Icon icon="loading" /> : text.check}
          </div>
        </>
      )}
    </>
  );
};

const CheckIcon = () => (<svg className="check-svg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 16.333C2 13.5046 2 12.0904 2.87868 11.2117C3.75736 10.333 5.17157 10.333 8 10.333H16C18.8284 10.333 20.2426 10.333 21.1213 11.2117C22 12.0904 22 13.5046 22 16.333C22 19.1614 22 20.5756 21.1213 21.4543C20.2426 22.333 18.8284 22.333 16 22.333H8C5.17157 22.333 3.75736 22.333 2.87868 21.4543C2 20.5756 2 19.1614 2 16.333Z" stroke="white" stroke-width="1.5"></path>
    <path d="M12 18.333C13.1046 18.333 14 17.4376 14 16.333C14 15.2284 13.1046 14.333 12 14.333C10.8954 14.333 10 15.2284 10 16.333C10 17.4376 10.8954 18.333 12 18.333Z" stroke="white" stroke-width="1.5"></path>
    <path d="M6 10.333V8.33301C6 5.0193 8.68629 2.33301 12 2.33301C15.3137 2.33301 18 5.0193 18 8.33301V10.333" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
  </svg>)
  
export default TaskModalInfo;
