import { motion } from "framer-motion";
import { createContext, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import generateCurvePoints from "utils/generate-сurve-points";

type AnimationConfig = {
  balanceValue: number;
  rewardValue: number;
  onEndAnimation?: () => void;
};

type RewardAnimationContextProps = {
  balance:number
  reward:number
  endTargetRefAnimation:React.RefObject<HTMLElement>
  startTargetRefAnimation:React.RefObject<HTMLElement>
  startAnimation:(config?: AnimationConfig) => void
  animationItems:JSX.Element[]
  animateBalanceBox:boolean
  setAnimateBalanceBox:Dispatch<SetStateAction<boolean>>
  animatedReward:boolean
  setConfig:(config: AnimationConfig) => void
}

export const RewardAnimationContext = createContext({} as RewardAnimationContextProps);

export default ({
  balanceValue,
  rewardValue,
  onEndAnimation:end,
}: AnimationConfig) => {
  const [balance, setbalance] = useState(balanceValue); // user?.balance || 0
  const [reward, setreward] = useState(rewardValue);
  const onEndAnimation = useRef<(() => void) | undefined>(end)
  const [animatedReward, setanimatedReward] = useState(false);
  let animationItemsCount = reward < 15 ? reward : 15;
  const singlecount = (reward / animationItemsCount || 1)
  const [animateBalanceBox, setAnimateBalanceBox] = useState(false);
  const endTargetRefAnimation = useRef<HTMLElement>(null);
  const startTargetRefAnimation = useRef<HTMLElement>(null);
  const [animationItems, setanimationItems] = useState<JSX.Element[]>([]);

  const setConfig = (config: AnimationConfig) => {
    setreward(config.rewardValue);
    setbalance(config.balanceValue);
    onEndAnimation.current = config.onEndAnimation
  }

  const startAnimation = () => {
    if (animatedReward) return;
    setanimatedReward(true);
  };

  const addAnimElement = () => {
    if (!endTargetRefAnimation.current || !startTargetRefAnimation.current)
      return;

    const startRect = startTargetRefAnimation.current.getBoundingClientRect();

    const endRect = endTargetRefAnimation.current.getBoundingClientRect();

    const cloneNode = startTargetRefAnimation.current.cloneNode(
      true
    ) as HTMLElement;

    // Генерируем точки для кривой
    const points = generateCurvePoints(
      { x: startRect.left, y: startRect.top },
      { x: endRect.left, y: endRect.top },
      15, // Количество точек
      10
    );

    // Извлекаем массив значений для анимации
    const topValues = points.map((point) => point.y);
    const leftValues = points.map((point) => point.x);
    const id = Date.now();
    setanimationItems([
      ...animationItems,
      <motion.div
        key={id}
        initial={{
          top: startRect.top,
          left: startRect.left,
          width: startRect.width,
          height: startRect.height,
        }}
        animate={{
          top: topValues,
          left: leftValues,
          width: endRect.width,
          height: endRect.height,
        }}
        transition={{ ease: "easeInOut", duration: 0.7 }}
        className="animated-reward-item"
        onAnimationComplete={() => {
          setAnimateBalanceBox(true);
          setbalance((prev) => prev + singlecount);
          setanimationItems((prev) =>
            prev.filter(({ key }) => key !== String(id))
          );
        }}
        dangerouslySetInnerHTML={{ __html: cloneNode.outerHTML }}
      />,
    ]);
  };

  useEffect(() => {
    if (!animatedReward) return;
    addAnimElement();
    if (Math.floor(reward) <= 0) {
      setanimatedReward(false)
      if (onEndAnimation.current) setTimeout(onEndAnimation.current, 700);
    }
  }, [reward]);

  useEffect(() => {
    if (!animatedReward) return;
    for (let i = 0; i < animationItemsCount; i++) {
      setTimeout(() => {
        setreward((prev) => prev - singlecount);
      }, i * 70);
    }
  }, [animatedReward])

  const RewardAnimationContextValue = {
    balance,
    reward,
    endTargetRefAnimation,
    startTargetRefAnimation,
    startAnimation,
    animationItems,
    animateBalanceBox,
    setAnimateBalanceBox,
    animatedReward,
    setConfig
  }

  return {
    RewardAnimationContext,
    RewardAnimationContextValue
  };
};
